<template>
  <div class="card">
    <div class="card-header border-0">
      <h3 class="mb-0 text-left">Players</h3>
    </div>

    <div class="card-body">
      <form class="form-inlin" @submit.prevent="searchHandler">
        <div class="row">
          <div class="form-group col-4">
            <input id="email" type="text" class="form-control" v-model="form.email"
                   placeholder="search for email address"/>
          </div>
          <div class="form-group col-3">
            <input id="id" type="text" class="form-control" v-model="form.id" placeholder="search for player id"/>
          </div>
          <div class="col-2">
            <button type="submit" class="btn btn-primary mb-2">Search</button>
          </div>
        </div>
      </form>

      <div class="table-responsive">
        <table class="table align-items-center table-flush">
          <thead class="thead-light">
          <tr class="text-left">
            <th scope="col">ID</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Email Address</th>
            <th scope="col">Gender</th>
            <th scope="col">DOB</th>
            <th scope="col">Weight</th>
            <th scope="col">Height</th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody class="list" v-if="players.data">
          <tr class="text-left" v-for="player of players.data" :key="player.id">
            <td>{{ player.userId }}</td>
            <td>{{ player.firstname }}</td>
            <td>{{ player.lastname }}</td>
            <td>{{ player.email }}</td>
            <td>{{ (player.gender) ? player.gender : 'Male' }}</td>
            <td>{{ player.dob }}</td>
            <td>{{ player.weight }} kg</td>
            <td>{{ player.height }} cm</td>
            <td>
              <img v-if="player.avatar" :src="player.avatar" width="50"
                   height="50"
                   alt="" class="img-fluid img-thumbnail"/>
            </td>
            <td class="text-right">
              <div class="btn-group" role="group" aria-label="Basic example">
                <router-link class="btn btn-sm btn-outline-primary"
                             :to="{ name: 'AdminPlayersEdit', params: {id: player.id }}">
                  View Profile
                </router-link>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="mx-auto">
        <pagination v-model="page" :records="total" :per-page="perPage" @paginate="getPlayers"  theme="bootstrap4"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Pagination from 'vue-pagination-2';

export default {
  name: 'AdminPagesList',
  data() {
    return {
      page: 1,
      form: {}
    }
  },

  components: {
    Pagination
  },

  computed: {
    ...mapGetters('player', ['players']),

    total() {
      if(!this.players) return 0;
      return this.players.total;
    },

    perPage() {
      if(!this.players) return 0;
      return parseInt(this.players.per_page);
    }
  },

  mounted() {
    this.$store.dispatch('player/players', {page: this.page});
  },

  methods: {
    getPlayers(page = 1) {
      this.page = page;
      this.$store.dispatch('player/players', {page: this.page});
    },

    searchHandler() {
      this.page = 1;
      this.$store.dispatch('player/players', {page: this.page, email: this.form.email, id: this.form.id});
    }
  }
}
</script>

<style scoped>
.VuePagination__count.VuePagination__count {
  color: #2d8968;
}
</style>

